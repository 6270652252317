import React, { Suspense, lazy } from "react";
import Default from "../components/Default";
import Loader from "../elements/Loader/Loader";
const LinkedInContent = lazy(() => import("../elements/LinkedIn/LinkedInContent"));

const LinkedIn = () => {
	return (
		<Default height="90%" heading="LinkedIn" resizable={false} programName="Web">
			<Suspense fallback={<Loader />}>
				<LinkedInContent />
			</Suspense>
		</Default>
	);
};

export default LinkedIn;

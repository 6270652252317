/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import "@styles/dock.scss";
import FinderIcon from "@static/finder.png";
import CodeIcon from "@static/code.png";
import GithubIcon from "@static/github.png";
import GitIcon from "@static/git.png";
import FolderIcon from "@static/folder.png";
import LinkedInIcon from "@static/LinkedInIcon.png";
import { Link } from "react-router-dom";
import mIcon from "@static/mIcon.jpg";


const DockContent = () => {
	return (
		<div className="main-contain">
			<div className="container">
				<div className="dock">
					<span></span>
					<div className="dock-nav">
						<ul>
							<Link to="/">
								<li data-title="Home" className="full-width-icon" >
									<img src={FinderIcon} className="img-fluid" alt="Home" />
								</li>
							</Link>
							<Link to="/vscode">
								<li data-title="VS Code">
									<img src={CodeIcon} className="img-fluid" alt="VS Code" />
								</li>
							</Link>
							<Link to="/git">
								<li data-title="Git Log">
									<img src={GitIcon} className="img-fluid" alt="Git Log" />
								</li>
							</Link>
							<a href="https://www.linkedin.com/in/dan-pask/" target="_blank">
								<li data-title="LinkedIn">
									<img src={LinkedInIcon} className="img-fluid" alt="LinkedIn" />
								</li>
							</a>
							<a href="https://dan.pask.dev/" target="_blank">
								<li data-title="Dan Pask">
									<img src={mIcon} className="img-fluid" alt="Min Info" />
								</li>
							</a>
							<a href="https://github.com/dpaskal" target="_blank">
								<li data-title="My GitHub">
									<img src={GithubIcon} className="img-fluid" alt="GitHub" />
								</li>
							</a>
							<div className="separator" />
							<Link to="/resume">
								<li data-title="Resume">
									<img src={FolderIcon} className="img-fluid" alt="Resume" />
								</li>
							</Link>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DockContent;